import autocomplete from 'autocompleter'
import myPostAxios from "../services/myPostAxios"
import UIkit from 'uikit';

import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { english } from 'flatpickr/dist/l10n/default.js';

/**
 ** Utils
 */

const Utils = () => {
    const textInputs = document.querySelectorAll('.kg-input-close')
    if (textInputs) {
        [...textInputs].forEach((textInput) => {
            textInput.onkeyup = function () {
                const clearBtn = textInput.nextElementSibling
                clearBtn.style.visibility = (this.value.length) ? "visible" : "hidden"
                clearBtn.onclick = function () {
                    this.style.visibility = "hidden"
                    textInput.value = ""
                };
            };
        })
    }

    const event_autocomplete = document.querySelectorAll('.kg-event-autocomplete')
    if (event_autocomplete) {
        [...event_autocomplete].forEach((inputText) => {
            autocomplete({
                input: inputText,
                minLength: 0,
                debounceWaitMs: 60,
                preventSubmit: true,
                disableAutoSelect: false,
                emptyMsg: 'Aucune correspondance trouvée',
                fetch: async function (text, update, trigger) {
                    //let sugg = [{ 'label': 'Ma position', value: 'geolocation' }];
                    
                    let sugg = [];
                    
                    await checkGeolocationPermission()
                       .then(function(permissionGranted) {
                           if (permissionGranted) {
                            sugg = [{ 'label': 'Ma position', value: 'geolocation' }];
                        }
                    });

                    //console.log(sugg);

                    if (inputText.id == 'itineraire_start' && localStorage.getItem('lastFromSearches')) {
                        let storedFromSearch = JSON.parse(localStorage.getItem('lastFromSearches'));
                        //console.log(storedFromSearch);
                        //console.log( Object.values(storedFromSearch));
                        if(storedFromSearch) {
                            storedFromSearch = storedFromSearch.reverse();
                            
                            for (const fromSearch of Object.values(storedFromSearch)) {
                                //console.log(fromSearch);
                                sugg.push({ label: fromSearch.label, value: fromSearch.value, group: "Dernières recherches" })
                            }
                        }
                        //console.info(sugg);
                    } else if (inputText.id == 'itineraire_end' && localStorage.getItem('lastToSearches')) {
                        let storedToSearch = JSON.parse(localStorage.getItem('lastToSearches'));
                        if(storedToSearch) {
                            storedToSearch = storedToSearch.reverse();
                            for (const toSearch of Object.values(storedToSearch)) {
                                sugg.push({ label: toSearch.label, value: toSearch.value, group: "Dernières recherches" })
                            }
                        }
                        // console.info(sugg);
                    }

                    if (text && text.length > 2) {
                        //console.log(inputText.form.dataset.url);
                        inputText.previousElementSibling.removeAttribute("hidden")   //spinner
                        //getDataAutocomplete(inputText, text, update, "AutocompleteItineraire")
                        getDataAutocomplete(inputText, text, update, "autocomplete_places")
                    } else {
                        if(sugg.length > 0) {
                            update(sugg);
                        }
                    }
                },
                click: function (e) { e.fetch(); },
                onSelect: function (item) {
                    //console.log(item);
                    inputText.previousElementSibling.setAttribute("hidden", "")
                    inputText.value = item.label;
                    //console.log(inputText.dataset.target);
                    const target = document.getElementById(inputText.dataset.target);
                    //console.log(target);
                    if (target) {
                        if (item.value == 'geolocation') {
                            navigator.geolocation.getCurrentPosition(
                                function (pos) {
                                    const coords = pos.coords;
                                    target.value = `${coords.longitude};${coords.latitude}`;
                                    target.nextElementSibling.value = item.label;
                                },
                                function (error) {
                                    UIkit.notification('Votre position n\'a pas été trouvée', { status: 'error', pos: 'bottom-right' })
                                    console.warn(error);
                                    inputText.value = ''
                                }
                            )
                        } else {
                            target.value = item.value;
                            target.nextElementSibling.value = item.label;
                        }
                    } else {
                        inputText.value = ''
                    }
                    inputText.blur();
                },
                render: function (item) {
                    inputText.previousElementSibling.setAttribute("hidden", "")
                    // console.log(item);
                    const itemElement = document.createElement("div");
                    itemElement.textContent = item.label;
                    return itemElement;
                }
            });
        })
    }
    const getDataAutocomplete = (input, text, update, endpoint) => {
        let post = {
            requete: endpoint,
            requete_val: {
                request: text
            }
        };
        //myPostAxios('?type=476', post).then((data) => {
        myPostAxios(input.form.dataset.url, post).then((data) => {
            if (data) {
                let sugg = []
                for (let i = 0; i < data.length; i++) {
                    sugg.push({ label: data[i].label, value: data[i].value })
                }
                update(sugg)
            } else {
                input.value = ''
                input.previousElementSibling.setAttribute("hidden", "")
                UIkit.notification("Aucune correspondance trouvée", { status: 'error', pos: 'bottom-right' })
                update([]);
            }
        }).catch((err) => {
            console.log(err.responseText)
            input.value = ''
            input.previousElementSibling.setAttribute("hidden", "")
            UIkit.notification("Aucune correspondance trouvée", { status: 'error', pos: 'bottom-right' })
            update([]);
        });
    }

    async function checkGeolocationPermission() {
        return new Promise((resolve, reject) => {
          console.log('Nav permissions', navigator.permissions); 
          if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(function(permissionStatus) {
                console.log('permission status', permissionStatus);
              if (permissionStatus.state === 'granted') {
                resolve(true); 
              } else {
                resolve(false); 
              }
            }).catch(reject);
          } else {
            console.log('no permission found');
            resolve(true);
          }
        });
    }
}


const ReverseDestination = () => {
    const btnReverse = document.querySelector('.event-reverse')
    if (!btnReverse) {
        return false
    }
    btnReverse.onclick = function () {
        const depart = document.getElementById('itineraire_start');
        const arrivee = document.getElementById('itineraire_end');

        [depart.value, arrivee.value] = [arrivee.value, depart.value];

        const departId = document.getElementById('start2_id');
        const arriveeId = document.getElementById('end_id');

        [departId.value, arriveeId.value, departId.nextElementSibling.value, arriveeId.nextElementSibling.value] = [arriveeId.value, departId.value, arriveeId.nextElementSibling.value, departId.nextElementSibling.value];

    };
};

//TODO: refacto
const GeolocationFrom = () => {
    const geolocateEvents = document.querySelector('.event-geolocation-start')

    //console.info(geolocateEvents);

    if (!geolocateEvents) {
        return false
    }

    geolocateEvents.onclick = function () {
        //console.info('click fired');
        navigator.geolocation.getCurrentPosition(
            function (pos) {
                const coords = pos.coords;
                // console.info('position', coords);

                const depart = document.getElementById('itineraire_start');
                depart.value = 'Ma position';
                const departId = document.getElementById('start2_id');
                departId.value = `${coords.longitude};${coords.latitude}`;
                departId.nextElementSibling.value = 'Lieu actuel';
            },
            function (error) {
                UIkit.notification('Votre position n\'a pas été trouvée', { status: 'error', pos: 'bottom-right' })
                console.warn(error);
            }
        );
    }
}


const GeolocationTo = () => {
    const geolocateEvents = document.querySelector('.event-geolocation-end')

    //console.info(geolocateEvents);

    if (!geolocateEvents) {
        return false
    }

    geolocateEvents.onclick = function () {
        //console.info('click fired');
        navigator.geolocation.getCurrentPosition(
            function (pos) {
                const coords = pos.coords;
                console.info('position', coords);

                const depart = document.getElementById('itineraire_end');
                depart.value = 'Ma position';
                const departId = document.getElementById('end_id');
                departId.value = `${coords.longitude};${coords.latitude}`;
                departId.nextElementSibling.value = 'Lieu actuel';
            },
            function (error) {
                UIkit.notification('Votre position n\'a pas été trouvée', { status: 'error', pos: 'bottom-right' })
                console.warn(error);
            }
        );
    }
}

const StoreLastSearch = () => {
    const submitButton = document.querySelector('#form_itineraire_submit');
    if (!submitButton) {
        return false;
    }

    submitButton.onclick = function () {
        const fromInput = document.getElementById('itineraire_start');
        const fromId = document.getElementById('start2_id');
        if(fromInput.value) {
            let storedFromSearch = new Array();
            const lastFromSearch = { 'label': fromInput.value, 'value': fromId.value };
            if (localStorage.getItem('lastFromSearches')) {
                storedFromSearch = JSON.parse(localStorage.getItem('lastFromSearches'));
                for (let index in storedFromSearch)
                    if (storedFromSearch[index].value === lastFromSearch.value) storedFromSearch.splice(index, 1)
                if (storedFromSearch.length == 5) {
                    storedFromSearch.shift();
                }
            }
            storedFromSearch.push(lastFromSearch);
            localStorage.setItem('lastFromSearches', JSON.stringify(storedFromSearch));
        }

        const toInput = document.getElementById('itineraire_end');
        const toId = document.getElementById('end_id');
        if(toInput.value) {
            let storedToSearch = new Array();
            const lastToSearch = { 'label': toInput.value, 'value': toId.value };

            if (localStorage.getItem('lastToSearches')) {
                storedToSearch = JSON.parse(localStorage.getItem('lastToSearches'));
                for (let index in storedToSearch)
                    if (storedToSearch[index].value === lastToSearch.value) storedToSearch.splice(index, 1)
                if (storedToSearch.length == 5) {
                    storedToSearch.shift();
                }
            }
            storedToSearch.push(lastToSearch);
            localStorage.setItem('lastToSearches', JSON.stringify(storedToSearch));
        }
    }

    // return false;
}


const Datepicker = () => {

    const $datePickerInputs = [...document.querySelectorAll('.uk-input-date')];
    const $hourPickerInputs = [...document.querySelectorAll('.uk-input-hour')];
    const getLocale = document.querySelector('html').getAttribute('lang');
    let local = getLocale === 'en-US' ? english : French;
    let altFormat = getLocale === 'en-US' ? "F j, Y" : "l j F Y";

    function initializeDatepicker( input ) {
        let $datePicker = flatpickr(input, {
            locale: local,
            altInput: true,
            altFormat: altFormat,
            minDate: "today",
            disableMobile: "true",
            onReady: function() {
                let $previousInput = this.altInput.previousElementSibling;
                let input_id = $previousInput.getAttribute('id');
                $previousInput.removeAttribute('id');
                this.altInput.id = input_id;
            }
        });
    }
    function initializeHourpicker( input ) {
        let $hourPicker = flatpickr(input, {
            enableTime: true,
            enableSeconds: false,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            disableMobile: "true"
        });
    }

    $datePickerInputs.forEach(input => initializeDatepicker(input));
    $hourPickerInputs.forEach(input => initializeHourpicker(input));
};

const DisplayDateForm = () => {
    const departureSelect = document.querySelector('#itineraire_apartirde');

    if(!departureSelect) {
        return false;
    }

    departureSelect.onchange = function() {
        if(departureSelect.value == '') {
            document.querySelector('#kg-form-itineraire__date-subform').setAttribute('hidden', true);
        } else {
            document.querySelector('#kg-form-itineraire__date-subform').removeAttribute('hidden');
        }
    }
}



/**
 ** Init All Widgets
 */
const KGWidgetItineraire = () => {
    Utils()
    ReverseDestination()
    GeolocationFrom()
    GeolocationTo()
    StoreLastSearch()
    Datepicker()
    DisplayDateForm()
}

document.addEventListener('DOMContentLoaded', KGWidgetItineraire);
